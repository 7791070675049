import { checkActivityFunctions, registerApplication, start } from "single-spa";

import { SingleSpaCustomProps } from "@bwll/bw-types";

import { queryClient } from "./reactQuery";

const RENT_ADVANTAGE_ROOT_PATH = "/rent-advantage";
const MARKETPLACE_APP_ROOT_PATH = "/marketplace";
const DASHBOARD_APP_ROOT_PATH = "/dashboard";

registerApplication<SingleSpaCustomProps>({
  name: "@bwll/navbar",
  app: () => System.import("@bwll/navbar"),
  // Only activating universal navbar on the selected app root paths to avoid
  // the navbar being shown on the rent-advantage app.
  activeWhen: [MARKETPLACE_APP_ROOT_PATH, DASHBOARD_APP_ROOT_PATH],
  customProps: {
    queryClient,
    domElementGetter: () => document.getElementById("sspa-layout-navbar") ?? undefined,
  },
});

registerApplication<SingleSpaCustomProps>({
  name: "@bwll/rent-advantage",
  app: () => System.import("@bwll/rent-advantage"),
  activeWhen: [RENT_ADVANTAGE_ROOT_PATH],
  customProps: {
    activatedPath: RENT_ADVANTAGE_ROOT_PATH,
    queryClient,
    domElementGetter: () => document.getElementById("sspa-layout-app") ?? undefined,
  },
});

registerApplication<SingleSpaCustomProps>({
  name: "@bwll/marketplace",
  app: () => System.import("@bwll/marketplace"),
  activeWhen: [MARKETPLACE_APP_ROOT_PATH],
  customProps: {
    activatedPath: MARKETPLACE_APP_ROOT_PATH,
    queryClient,
    domElementGetter: () => document.getElementById("sspa-layout-app") ?? undefined,
  },
});

registerApplication<SingleSpaCustomProps>({
  name: "@bwll/dashboard",
  app: () => System.import("@bwll/dashboard"),
  activeWhen: [DASHBOARD_APP_ROOT_PATH],
  customProps: {
    activatedPath: DASHBOARD_APP_ROOT_PATH,
    queryClient,
    domElementGetter: () => document.getElementById("sspa-layout-app") ?? undefined,
  },
});

// Start the single-spa application.
start({
  urlRerouteOnly: true,
});

// Redirect to the fallback application if the URL is not registered.
// This is to prevent the blank screen being shown to the user.
if (checkActivityFunctions(window.location).length === 0) {
  window.location.replace(process.env.FALLBACK_WEB_APP_URL as string);
}
